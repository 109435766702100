<template>
  <a-modal
    v-model="visible"
    :title="title"
    width="716px"
    :destroyOnClose="true"
    :bodyStyle="{ padding: 0 }"
    :ok-button-props="{ props: { shape: 'round' } }"
    :cancel-button-props="{ props: { shape: 'round' } }"
    :afterClose="handleClose"
    @ok="visible = false"
  >
    <a-row style="padding: 24px">
      <a-form-model class="ant-advanced-search-form">
        <a-col :lg="10" :md="12" :sm="24">
          <a-form-model-item label="姓名">
            <a-input-search
              v-model="userName"
              allowClear
              placeholder="请输入姓名"
              style="width: 100%"
              @search="initData"
            />
          </a-form-model-item>
        </a-col>
      </a-form-model>
    </a-row>

    <teacher-table ref="table" :type="type" @change="changed = true"></teacher-table>
  </a-modal>
</template>

<script>
import TeacherTable from './TeacherTable'

export default {
  components: {
    TeacherTable
  },
  data() {
    return {
      visible: false,
      changed: false,
      userName: undefined,
      type: ''
    }
  },
  computed: {
    title() {
      return { teacher: '绑定授课教师', planWriter: '绑定计划撰写师' }[this.type]
    }
  },
  methods: {
    showModal(query = {}) {
      this.visible = true

      this.type = query.type
      delete query.type

      this.query = query
      this.initData()
    },
    initData() {
      const { $nextTick, query, userName, $refs } = this

      $nextTick(() => {
        $refs.table.initData({ ...query, userName })
      })
    },
    handleClose() {
      if (this.changed) {
        this.changed = false
        this.$emit('change')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.ant-advanced-search-form /deep/ .ant-form-item {
  display: flex;
  margin-bottom: 0;
}
.ant-advanced-search-form /deep/ .ant-form-item-control-wrapper {
  flex: 1;
}
</style>
