<template>
  <a-modal
    centered
    bordered
    v-model="visible"
    :width="450"
    title="删除学员"
    :destroyOnClose="true"
    :bodyStyle="{ padding: 0 }"
    :ok-button-props="{ props: { loading, disabled: form.phone.length === 0 } }"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-card :bordered="false" :body-style="{ padding: '30px 20px 10px 20px' }">
      <a-form-model ref="form" :model="form" :labelCol="{ span: 5 }" :wrapperCol="{ span: 15 }">
        <a-form-model-item label="手机号：" prop="phone" :rules="checkRule">
          <a-input v-model.trim="form.phone" :maxLength="11" />
        </a-form-model-item>
      </a-form-model>
    </a-card>
  </a-modal>
</template>

<script>
import { cloneDeep } from 'lodash'
import * as studentApi from '@/api/student'
export default {
  data() {
    const Validator = (rule, value, callback) => {
      const idcardReg = /^1[23456789]\d{9}$/g
      if (!idcardReg.test(value)) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('手机号格式错误')
      }
      callback()
    }
    return {
      checkRule: [
        { validator: Validator, trigger: ['change', 'blur'] },
        {
          required: true,
          max: 11,
          min: 11,
          message: '请输入',
          trigger: ['change', 'blur']
        }
      ],
      visible: false,
      loading: false,
      form: {
        phone: ''
      }
    }
  },
  methods: {
    showModal(callback) {
      this.visible = true
      this.callback = callback
    },
    validate() {
      const {
        $refs: { form }
      } = this
      return new Promise((resolve, reject) => {
        form.validate((valid, err) => {
          if (valid) {
            resolve(valid)
            return
          }
          reject(err)
        })
      })
    },
    handleOk() {
      const { callback } = this
      const { $notification, validate } = this
      validate().then(valid => {
        if (valid) {
          this.loading = true
          try {
            studentApi
              .remove({ ...cloneDeep(this.form) })
              .then(res => {
                $notification['success']({ message: '操作成功' })
                callback()
                this.callback = null
                this.visible = false
              })
              .finally(() => {
                this.loading = false
                this.form.phone = ''
              })
          } catch {
            $notification['error']({ message: '提示', description: '脚本错误' })
            this.loading = false
          }
        }
      })
    },
    handleCancel() {
      this.form.phone = ''
    }
  }
}
</script>

<style lang="less" scoped></style>
