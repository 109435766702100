<template>
  <div>
    <a-card style="margin-bottom: 10px">
      <a-row type="flex" :gutter="[16, 16]">
        <a-col :lg="6" :md="8" :xs="24" class="option-wrapper">
          <span>机构名称：</span>
          <h-cascader
            :options="mechanismOptions"
            :value="defaultMechanism"
            :showSearch="true"
            change-on-select
            @change="onChange"
            :fieldNames="{ label: 'name', value: 'id', children: 'childrenList' }"
            placeholder="请选择"
            style="flex: 1"
          >
          </h-cascader>
        </a-col>
        <a-col :lg="6" :md="8" :xs="24">
          <a-input-group compact style="display: flex">
            <a-select default-value="username" v-model="searchType">
              <a-select-option value="username"> 学员姓名 </a-select-option>
              <a-select-option value="guardians"> 监护人 </a-select-option>
              <a-select-option value="cellphone"> 联系电话 </a-select-option>
            </a-select>
            <a-input-search v-model="searchTypeContent" allowClear placeholder="请输入" @search="initData" />
          </a-input-group>
        </a-col>

        <a-col :lg="6" :md="8" :xs="24" class="option-wrapper">
          <span>注册日期：</span>
          <a-range-picker style="flex: 1" valueFormat="YYYY-MM-DD" allowClear @change="onDatechange" />
        </a-col>
        <a-col :lg="6" :md="8" :xs="24" class="option-wrapper">
          <span>学生状态：</span>
          <a-select
            style="flex: 1"
            v-model="query.schoolStatus"
            @change="initData"
            placeholder="请选择学生状态"
            allowClear
          >
            <a-select-option :value="item.value" v-for="item in studentList" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select></a-col
        >
        <!-- <a-col :lg="6" :md="8" :xs="24" class="option-wrapper">
          <span>绑定设备：</span>
          <a-select style="flex:1" v-model="query.isBindMachineCode" @change="initData">
            <a-select-option value="true">
              嗨小保
            </a-select-option>
            <a-select-option value="false">
              无
            </a-select-option>
          </a-select></a-col
        > -->
      </a-row>
    </a-card>

    <a-card :bodyStyle="{ padding: 0 }">
      <div slot="title" style="display: flex; justify-content: space-between">
        <span>学员列表</span>
        <div class="statistics">
          <span
            >学生创建上限：{{
              maximumNumber.studentRegistrationLimit === unlimitedNumber
                ? '无限制'
                : maximumNumber.studentRegistrationLimit
            }}</span
          >
          <span>已有学生数量：{{ maximumNumber.studentRegistrationCount }}</span>
          <span>在园学生数量：{{ maximumNumber.admissionStudentNum }}</span>
        </div>
      </div>
      <div slot="extra">
        <a-button
          type="primary"
          shape="round"
          v-if="$auth('student_add')"
          @click="onAdd"
          :disabled="maximumNumber.studentRegistrationCount >= maximumNumber.studentRegistrationLimit"
          >机构学员注册</a-button
        >
        <a-button type="danger" v-if="$auth('student_delete')" shape="round" @click="onRemove">删除学员</a-button>
      </div>
      <main-table ref="table" v-if="$auth('student_list')" @getMaxPeopleNumber="getMaxPeopleNumber"></main-table>
    </a-card>

    <!--删除学员-->
    <delete-student-model ref="deleteStudentModel" />
  </div>
</template>

<script>
import * as commonApi from '@/api/common'
import MainTable from './components/Table'
import deleteStudentModel from './components/Model'
import { mapState } from 'vuex'
import { merge } from '@/utils/util'
import { cloneDeep } from 'lodash'
import { SCHOOL_STATUS_STATUS } from '@/store/dictionary'

export default {
  name: 'StudentManagementList',
  components: {
    MainTable,
    deleteStudentModel,
  },
  data() {
    return {
      query: {
        mechanismId: undefined,
        cellphone: undefined,
        username: undefined,
        guardians: undefined,
        startTimestamp: undefined,
        endTimestamp: undefined,
        isBindMachineCode: undefined,
        schoolStatus: undefined,
      },
      searchType: 'username',
      searchTypeContent: '',
      defaultMechanism: [],
      mechanismOptions: [],
      // 学员管理、机构管理、员工管理上限
      maximumNumber: {},
      studentList: SCHOOL_STATUS_STATUS,
    }
  },

  computed: {
    ...mapState({
      // 无限制的数量
      unlimitedNumber: (state) => state.user.unlimitedNumber,
    }),
  },

  activated() {
    if (this.ready) {
      this.initData()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.ready = true
    })
    commonApi.mechanismOptions().then((res) => {
      this.mechanismOptions = res.data
    })

    this.initData()
  },

  methods: {
    initData() {
      const query = cloneDeep(this.query)
      query[this.searchType] = this.searchTypeContent
      // 编辑返回要记住页码
      const { query: pagination } = this.$route

      this.$refs.table.initData(query, pagination)
      // 学员管理、机构管理、员工管理上限
      this.getMaxPeopleNumber()
    },
    // 添加学员
    onAdd() {
      this.$router.push({
        name: 'StudentManagementAdd',
      })
    },
    onRemove() {
      const {
        $refs: { deleteStudentModel },
        $nextTick,
        initData,
      } = this
      $nextTick(() =>
        deleteStudentModel.showModal((e) => {
          initData()
        })
      )
    },
    // 日期筛选
    onDatechange(date) {
      this.query.startTimestamp = date[0]
      this.query.endTimestamp = date[1]
      this.initData()
    },
    onChange(record) {
      this.defaultMechanism = record
      this.$set(this.query, 'mechanismId', record[record.length - 1])
      this.initData()
    },
    // 学员管理、机构管理、员工管理上限
    getMaxPeopleNumber() {
      commonApi.commonGetMechanism().then((res) => {
        const { code, data } = res
        if (code === 0) {
          this.maximumNumber = data
        }
      })
    },
  },
  watch: {
    searchType() {
      const temp = {
        cellphone: undefined,
        username: undefined,
        guardians: undefined,
      }
      this.searchTypeContent = ''
      merge(this.query, temp)
    },
  },
}
</script>

<style lang="less" scoped>
.option-wrapper {
  display: flex;
  align-items: center;
}

.ant-card-extra {
  button {
    margin-left: 10px;
  }
}
.statistics {
  span {
    margin-right: 24px;
    color: #595959;
    font-size: 14px;
  }
}
</style>
