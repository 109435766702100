<template>
  <a-table rowKey="id" :data-source="list" :pagination="pagination" :loading="listLoading" @change="handleTableChange">
    <a-table-column key="username" title="姓名" data-index="username" align="center" />

    <a-table-column key="cellphone" title="联系电话" data-index="cellphone" align="center" />

    <a-table-column key="email" title="邮箱" data-index="email" align="center" />

    <a-table-column key="actions" title="操作" align="center" width="240px" :ellipsis="true">
      <template slot-scope="record">
        <a-button
          v-if="type === 'teacher'"
          :class="isBind(record) ? 'error-color' : ''"
          type="link"
          size="small"
          @click="handleBind(record)"
        >
          <a-icon type="right-circle" />{{ isBind(record) ? '解除绑定' : '绑定' }}
        </a-button>
        <a-button v-if="type === 'planWriter'" type="link" size="small" @click="handleBind(record)">
          <a-icon type="right-circle" />绑定
        </a-button>
      </template>
    </a-table-column>
  </a-table>
</template>

<script>
import * as userApi from '@/api/user'
import * as studentApi from '@/api/student'

export default {
  props: {
    type: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      query: {},
      pagination: {},
      listLoading: false,
      list: []
    }
  },
  methods: {
    isBind(record) {
      const { type } = this

      if (type === 'teacher') {
        return record.isBindTeacher
      } else if (type === 'planWriter') {
        return record.isBindPlanWriter
      }
      return false
    },
    handleBind({ id: userId, isBindTeacher, isBindPlanWriter }) {
      const {
        $notification,
        type,
        query: { studentId },
        getData
      } = this

      const fn = {
        teacher: () => studentApi.bindTeacher({ studentId, userId, isBind: !isBindTeacher }),
        planWriter: () => studentApi.bindPlanWriter({ studentId, userId, isBind: true })
      }

      fn[type]().then(() => {
        $notification['success']({ message: '操作成功' })
        getData()
        this.$emit('change')
      })
    },
    initData(query = {}) {
      const { initPagination, getData } = this

      this.query = query
      this.pagination = initPagination()

      return getData()
    },
    getData() {
      const {
        pagination: { current: pageIndex, pageSize },
        query
      } = this

      this.listLoading = true

      return userApi
        .list4Student({ ...query, pageIndex, pageSize })
        .then(res => {
          const { list, totalItems: total } = res.data

          this.list = list
          this.pagination.total = total
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    initPagination() {
      return {
        current: 1,
        pageSize: 4,
        showTotal: total => {
          const h = this.$createElement
          const {
            pagination: { pageSize }
          } = this

          return h('div', {}, [
            h('span', '共 '),
            h('span', { class: 'primary-color' }, pageSize ? Math.ceil(total / pageSize) : Math.ceil(total / 4)),
            h('span', ' 页'),
            h('span', ' / '),
            h('span', { class: 'primary-color' }, total),
            h('span', ' 条数据')
          ])
        },
        total: 0
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const { getData } = this

      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize

      getData()
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-table-pagination.ant-pagination {
  margin: 24px;
}
</style>
