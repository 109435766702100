<template>
  <div>
    <a-table
      rowKey="id"
      :data-source="list"
      :pagination="pagination"
      :loading="listLoading"
      @change="handleTableChange"
    >
      <a-table-column key="username" title="学员" data-index="username" align="center" :ellipsis="true" width="13%" />

      <a-table-column key="sex" title="性别" data-index="sex" align="center" :ellipsis="true" width="7%">
        <template slot-scope="sex">
          <span>
            {{ { MALE: '男', FEMALE: '女' }[sex] }}
          </span>
        </template>
      </a-table-column>

      <a-table-column key="age" title="年龄" data-index="age" align="center" :ellipsis="true" width="8%">
        <template slot-scope="age">
          <span>
            {{ age ? age : '-' }}
          </span>
        </template>
      </a-table-column>

      <a-table-column key="guardians" title="监护人" data-index="guardians" align="center" :ellipsis="true" width="8%">
        <template slot-scope="guardians">
          <span>
            {{ guardians || '--' }}
          </span>
        </template>
      </a-table-column>

      <a-table-column
        key="cellphone"
        title="联系电话"
        data-index="cellphone"
        align="center"
        :ellipsis="true"
        width="10%"
      />

      <a-table-column
        key="createTimestamp"
        title="注册日期"
        data-index="createTimestamp"
        align="center"
        :ellipsis="true"
        width="10%"
      />

      <!--      <a-table-column-->
      <!--        key="serviceType"-->
      <!--        title="服务类型"-->
      <!--        data-index="serviceType"-->
      <!--        align="center"-->
      <!--        :ellipsis="true"-->
      <!--        width="14%"-->
      <!--      >-->
      <!--        <template slot-scope="serviceType">-->
      <!--          <span>-->
      <!--            {{ serviceType ? serviceType : '-' }}-->
      <!--          </span>-->
      <!--        </template>-->
      <!--      </a-table-column>-->
      <a-table-column
        key="mechanismName"
        title="服务机构"
        data-index="mechanismName"
        align="center"
        :ellipsis="true"
        width="17%"
      />

      <a-table-column
        key="planWriter"
        title="计划撰写师"
        data-index="planWriter"
        align="center"
        :ellipsis="true"
        width="10%"
      >
        <template slot-scope="planWriter">
          <span>
            {{ planWriter ? planWriter : '-' }}
          </span>
        </template>
      </a-table-column>

      <a-table-column key="teacher" title="授课教师" data-index="teacher" align="center" :ellipsis="true" width="10%">
        <template slot-scope="teacher">
          <span>
            {{ teacher ? teacher : '-' }}
          </span>
        </template>
      </a-table-column>

      <!-- <a-table-column
        key="machineCode"
        title="绑定设备"
        data-index="machineCode"
        align="center"
        :ellipsis="true"
        width="10%"
      >
        <template slot-scope="machineCode">
          <span>
            {{ machineCode ? '嗨小保' : '无' }}
          </span>
        </template>
      </a-table-column> -->
      <a-table-column
        key="schoolStatus"
        title="学员状态"
        align="center"
        data-index="schoolStatus"
        :ellipsis="true"
        width="13%"
      >
        <template slot-scope="schoolStatus">
          <a-badge
            :status="{ ADMISSION: 'success', WITHDRAWAL: 'error' }[schoolStatus]"
            :text="schoolStatus | schoolStatusText()"
          ></a-badge>
        </template>
      </a-table-column>

      <a-table-column key="actions" title="操作" align="center" :ellipsis="true" width="18%">
        <template slot-scope="record">
          <!-- <div style="display: flex; justify-content: center"> -->
          <div>
            <div style="display: flex; flex-direction: column; align-items: flex-start; width: 110px">
              <template v-if="record.schoolStatus === 'ADMISSION'">
                <a-button
                  v-if="$auth('bind_teacher') && record.serviceType.indexOf('家校训练服务') > -1"
                  type="link"
                  size="small"
                  @click="handleBind(record, 'teacher')"
                >
                  <a-icon type="right-circle" />绑定授课教师
                </a-button>
                <a-button
                  v-if="$auth('bind_planWrite') && record.serviceType.indexOf('家校训练服务') > -1"
                  :class="record.planWriter ? 'error-color' : ''"
                  type="link"
                  size="small"
                  @click="handleBind(record, 'planWriter')"
                >
                  <a-icon :type="record.planWriter ? 'sync' : 'right-circle'" />{{
                    record.planWriter ? '更换计划撰写师' : '绑定计划撰写师'
                  }}
                </a-button>
              </template>
              <a-dropdown v-if="$auth('student_update') || $auth('student_part_update') || $auth('school_status') || (!record.machineCode && $auth('student_account_association'))">
                <a class="ant-dropdown-link" @click="(e) => e.preventDefault()"> 更多 <a-icon type="down" /> </a>
                <a-menu slot="overlay">
                  <a-menu-item v-if="$auth('student_update')">
                    <a-button type="link" size="small" @click="handleEdit({ record })"
                      ><a-icon type="edit" />编辑</a-button
                    >
                  </a-menu-item>
                  <a-menu-item v-if="$auth('student_part_update')">
                    <a-button type="link" size="small" @click="handleEdit({ record, isDisabled: true })"
                      ><a-icon type="edit" />编辑学员</a-button
                    >
                  </a-menu-item>
                  <a-menu-item v-if="!record.machineCode && $auth('student_account_association')">
                    <a-button type="link" size="small" @click="handBindDevice({ record })"
                      ><a-icon type="edit" />账号关联</a-button
                    >
                  </a-menu-item>
                  <a-menu-item v-if="$auth('school_status')">
                    <a-button type="link" size="small" @click="handSchoolStatus({ record })"
                      ><a-icon type="edit" />状态管理</a-button
                    >
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
            <div v-if="isEmpty(record)">-</div>
          </div>
        </template>
      </a-table-column>
    </a-table>

    <teacher-modal ref="modal" @change="getData"></teacher-modal>
  </div>
</template>

<script>
import * as studentApi from '@/api/student'
import TeacherModal from './TeacherModal'
import store from '@/store'

export default {
  components: {
    TeacherModal,
  },
  data() {
    return {
      query: {},
      pagination: {},
      listLoading: false,
      list: [],
    }
  },
  activated() {
    const { ready, getData } = this

    if (ready) {
      getData()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.ready = true
    })
  },
  methods: {
    initData(query = {}, pagination) {
      const { initPagination, getData } = this

      this.query = query
      this.pagination = initPagination()
      // 编辑返回要记住页码
      if (pagination.hasOwnProperty('current') && pagination.hasOwnProperty('pageSize')) {
        const { current, pageSize } = pagination
        this.pagination.current = Number(current)
        this.pagination.pageSize = Number(pageSize)
      }
      return getData()
    },
    getData() {
      const {
        pagination: { current: pageIndex, pageSize },
        query,
      } = this

      this.listLoading = true

      return studentApi
        .list({ ...query, pageIndex, pageSize })
        .then((res) => {
          const { list, totalItems: total } = res.data
          this.list = list
          this.pagination.total = total
          store.dispatch('getInfo')
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    initPagination() {
      return {
        current: 1,
        pageSize: 10,
        showTotal: (total) => {
          const h = this.$createElement
          const {
            pagination: { pageSize },
          } = this

          return h('div', {}, [
            h('span', '共 '),
            h('span', { class: 'primary-color' }, pageSize ? Math.ceil(total / pageSize) : Math.ceil(total / 10)),
            h('span', ' 页'),
            h('span', ' / '),
            h('span', { class: 'primary-color' }, total),
            h('span', ' 条数据'),
          ])
        },
        showSizeChanger: true,
        total: 0,
        pageSizeOptions: ['10', '20', '50', '100'],
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const { getData } = this

      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize

      getData()
    },
    // 编辑学员
    handleEdit({ record, isDisabled = false }) {
      const { id } = record
      const {
        pagination: { current, pageSize },
      } = this
      const query = { isDisabled, current, pageSize }
      this.$router.push({
        name: 'StudentManagementEdit',
        params: { id },
        query,
      })
    },
    // 绑定上课老师、计划撰写师
    handleBind(record, type) {
      const { id: studentId, mechanismId } = record
      this.$refs.modal.showModal({ studentId, mechanismId, type })
    },
    // 操作按钮是否为空
    isEmpty(record) {
      const {serviceType, schoolStatus} = record
      // true 有按钮  false 无按钮
      const flag1 = schoolStatus === 'ADMISSION' ? (this.$auth('bind_planWrite') && serviceType.indexOf('家校训练服务') > -1) || (this.$auth('bind_planWrite') && serviceType.indexOf('家校训练服务') > -1) : false
      const flag2 = this.$auth('student_update') || this.$auth('student_part_update') || this.$auth('school_status') || (!record.machineCode && this.$auth('student_account_association'))
      return !flag1 && !flag2
    },
    // 绑定设备
    handBindDevice({ record }) {
      const { id } = record
      this.$router.push({
        name: 'StudentManagementBind',
        params: { id },
        query: {
          row: JSON.stringify(record), // 将对象转成json字符串
        },
      })
    },
    // 状态管理
    async handSchoolStatus({ record }) {
      let _params = {}
      // 获取学员详情
      await this.getStudentInfo(record).then((res) => {
        _params = res
      })
      const msg = record.schoolStatus === 'ADMISSION' ? '确定为当前学员进行离园吗?' : '确定为当前学员进行入园吗？'
      const confirm = this.$confirm({
        title: '信息提示',
        content: msg,
        okText: '确认',
        cancelText: '取消',
        okButtonProps: { props: { shape: 'round' } },
        cancelButtonProps: { props: { shape: 'round' } },
        onOk: () => {
          // 学员状态取反
          const schoolStatus = record.schoolStatus === 'ADMISSION' ? 'WITHDRAWAL' : 'ADMISSION'
          const params = { ..._params, ...{ schoolStatus } }
          // 更新学员状态
          studentApi.studentUpdate(params).then((res) => {
            const { code, msg } = res
            if (code === 0) {
              this.$notification['success']({ message: '操作成功' })
              // 重新获取列表
              this.getData()
              // 学员管理、机构管理、员工管理上限
              this.$emit('getMaxPeopleNumber')
              confirm.destroy()
            } else {
              this.$notification['error']({ message: '提示', description: `${msg}` })
            }
          })
        },
      })
    },

    // 获取学员详情
    getStudentInfo({ id }) {
      return new Promise((resolve, reject) => {
        studentApi.getStudentDetail({ studentId: id }).then((res) => {
          const {
            username,
            nickname,
            sex,
            birthday,
            cellphone,
            machineCode,
            guardians,
            mechanismVO: { id: mechanismId },
          } = res.data
          resolve({
            id,
            username,
            sex,
            birthday,
            cellphone,
            machineCode,
            mechanismId,
            nickname,
            guardians,
          })
          reject()
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
.desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-divider,
.ant-divider-vertical {
  margin: 0 16px;
}
/deep/ .ant-table-pagination.ant-pagination {
  margin: 24px;
}
@media (max-width: 1919px) {
  /deep/ .ant-btn {
    font-size: 12px;
  }
}
</style>
